var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('el-dialog', {
    attrs: {
      "close-on-click-modal": false,
      "title": _vm.title,
      "visible": _vm.visible,
      "width": "476px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "drawer-content",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.formValidate,
      "label-position": "top"
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "物料名称",
      "prop": "goodsName"
    }
  }, [_c('Input', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.form.goodsName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "goodsName", $$v);
      },
      expression: "form.goodsName"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "规格型号",
      "prop": "goodsModel"
    }
  }, [_c('Input', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.form.goodsModel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "goodsModel", $$v);
      },
      expression: "form.goodsModel"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '0',
      expression: "type!='0'"
    }],
    staticClass: "drawer-footer br",
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]), _c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };