var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "search"
  }, [_c('el-card', {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "cTitle",
    on: {
      "click": function ($event) {
        _vm.more = !_vm.more;
      }
    }
  }, [!_vm.more ? _c('i', {
    staticClass: "el-icon-caret-right"
  }) : _vm._e(), _vm.more ? _c('i', {
    staticClass: "el-icon-caret-bottom"
  }) : _vm._e(), _vm._v(" 高级筛选")]), _c('div', {
    staticClass: "btnAction"
  }, [_c('el-input', {
    staticStyle: {
      "width": "160px",
      "margin-right": "8px"
    },
    attrs: {
      "placeholder": "请输入物料名称"
    },
    model: {
      value: _vm.searchForm.goodsName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "goodsName", $$v);
      },
      expression: "searchForm.goodsName"
    }
  }), _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "mini"
    },
    on: {
      "click": _vm.getDataList
    }
  }, [_vm._v("搜索")])], 1)])]), _c('el-card', [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "cTitle"
  }, [_vm._v("物料管理")]), _c('div', {
    staticClass: "btnAction"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "plain": "",
      "round": "",
      "size": "mini"
    },
    on: {
      "click": _vm.add
    }
  }, [_vm._v("添加物料")])], 1)]), _c('div', {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c('vxe-table', {
    ref: "table",
    attrs: {
      "align": "center",
      "export-config": {
        types: ['csv'],
        isHeader: true
      },
      "print-config": {},
      "stripe": "",
      "size": "medium",
      "height": _vm.height - 147 - 50 - 95,
      "loading": _vm.loading,
      "column-config": {
        resizable: true
      },
      "row-config": {
        isHover: true
      },
      "data": _vm.data
    }
  }, [_c('vxe-column', {
    attrs: {
      "fixed": "left",
      "type": "checkbox",
      "width": "40"
    }
  }), _c('vxe-column', {
    attrs: {
      "field": "buildName",
      "title": "物料名称"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function ({
        column
      }) {
        return [_c('span', {
          staticStyle: {
            "color": "#909399"
          }
        }, [_vm._v(_vm._s(column.title))])];
      }
    }, {
      key: "default",
      fn: function ({
        row
      }) {
        return [_c('span', [_vm._v(_vm._s(row.goodsName))])];
      }
    }])
  }), _c('vxe-column', {
    attrs: {
      "field": "buildName",
      "title": "规格型号"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function ({
        column
      }) {
        return [_c('span', {
          staticStyle: {
            "color": "#909399"
          }
        }, [_vm._v(_vm._s(column.title))])];
      }
    }, {
      key: "default",
      fn: function ({
        row
      }) {
        return [_c('span', [_vm._v(_vm._s(row.goodsModel))])];
      }
    }])
  }), _c('vxe-column', {
    attrs: {
      "fixed": "right",
      "title": "操作",
      "width": "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "plain": "",
            "type": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          attrs: {
            "size": "mini",
            "plain": "",
            "type": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c('vxe-column', {
    attrs: {
      "fixed": "right",
      "width": "8"
    }
  })], 1), _c('vxe-pager', {
    attrs: {
      "loading": _vm.loading,
      "current-page": _vm.page.currentPage,
      "page-size": _vm.page.pageSize,
      "total": _vm.page.total,
      "layouts": ['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']
    },
    on: {
      "page-change": _vm.handlePageChange1
    }
  }), _c('addEdit', {
    attrs: {
      "data": _vm.form,
      "type": _vm.showType
    },
    on: {
      "on-submit": _vm.getDataList
    },
    model: {
      value: _vm.showDrawer,
      callback: function ($$v) {
        _vm.showDrawer = $$v;
      },
      expression: "showDrawer"
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };